import React, { Fragment } from "react"

import ErrorMessage from "elements/ErrorMessage"
import { hasIncompleteValues } from "../services/form"

const PrescriptionInformationSummary = ({ patientEnrollment }) => {
  if (
    hasIncompleteValues({
      data: patientEnrollment,
      flow: "patientEnrollmentPrescriptionInformation",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  const hospital = patientEnrollment?.hospitalName?.value

  return (
    <Fragment>
      <p className="mb-1-mobile">
        Hospital Name:{" "}
        <span className="has-text-weight-bold">
          <br className="is-hidden-desktop is-hidden-tablet" />
          {hospital === "Other" ? patientEnrollment?.otherHospital : hospital}
        </span>
      </p>
      <p className="mb-1-mobile">
        Prescribing Doctor:{" "}
        <span className="has-text-weight-bold">
          <br className="is-hidden-desktop is-hidden-tablet" />
          {patientEnrollment?.doctorName}
        </span>
      </p>
      <p className="mb-1-mobile">
        Medicine Prescribed:{" "}
        <span className="has-text-weight-bold">
          <br className="is-hidden-desktop is-hidden-tablet" />
          {patientEnrollment?.prescribedMedicine?.label}
        </span>
      </p>
      {patientEnrollment?.diseaseType && (
        <p className="mb-1-mobile">
          Subtype:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {patientEnrollment?.diseaseType}
          </span>
        </p>
      )}
      {patientEnrollment?.lineOfTreatment && (
        <p className="mb-1-mobile">
          Line of Treatment:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {patientEnrollment?.lineOfTreatment}
          </span>
        </p>
      )}
      {patientEnrollment?.therapyType && (
        <p className="mb-1-mobile">
          Therapy Type:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {patientEnrollment?.therapyType}
          </span>
        </p>
      )}
      {patientEnrollment?.combinationTherapyMedicines && (
        <p className="mb-1-mobile">
          Combination Therapy Medicine:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {patientEnrollment?.combinationTherapyMedicines}
          </span>
        </p>
      )}
    </Fragment>
  )
}

export default PrescriptionInformationSummary
