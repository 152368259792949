import React from "react"
import { Formik, Form } from "formik"

import FormInput from "../../Elements/Form/FormInput"
import FormRadio from "../../Elements/Form/FormRadio"
import FormCheckbox from "../../Elements/Form/FormCheckbox"
import ErrorMessage from "elements/ErrorMessage"

import { hasIncompleteValues } from "../services/form"
import { generatePaymentMethodMedicines } from "../services/enrollment"

const FinancialInformationSummary = ({ patientEnrollment }) => {
  if (
    hasIncompleteValues({
      data: patientEnrollment,
      flow: "patientEnrollmentFinancialInformation",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <Formik initialValues={patientEnrollment}>
      {() => (
        <Form>
          <FormCheckbox
            name="paymentMethod"
            title={
              <p className="has-text-grey has-text-weight-normal">
                How do you pay for your medicines?
              </p>
            }
            values={generatePaymentMethodMedicines({ patientEnrollment })}
            options={generatePaymentMethodMedicines({ patientEnrollment })}
            disabled
            isRequired
          />
          {patientEnrollment?.peopleInHousehold && (
            <FormInput
              name="peopleInHousehold"
              label={
                <p className="has-text-grey">
                  How many people are in your household including yourself?
                </p>
              }
              value={patientEnrollment?.peopleInHousehold}
              disabled
              hideOptional
            />
          )}
          {patientEnrollment.totalIncomeInHousehold && (
            <FormRadio
              name="totalIncomeInHousehold"
              title={
                <p className="has-text-grey has-text-weight-normal">
                  What is your total monthly household income?
                </p>
              }
              value={patientEnrollment.totalIncomeInHousehold}
              options={[patientEnrollment.totalIncomeInHousehold]}
              disabled
              isRequired
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default FinancialInformationSummary
